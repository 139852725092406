<template>
	<div>
		<!-- Header -->
		<b-container fluid style="padding: 100px 15px">
			<div>
				<b-row class="justify-content-center">
					<img
						src="../../../../client/src/assets/Logo/ComodeIcon.svg"
						class="logo"
						@click="$router.push('/adminApp/login')"
					/>
				</b-row>
				<div class="d-flex justify-content-center screen-title">ユーザー新規登録</div>
				<b-row class="pb-27 col-gap-30">
					<b-col>
						<validation-observer
							class="w-100"
							v-slot="{ handleSubmit }"
							ref="formValidator"
						>
							<b-form role="form" @submit.prevent="handleSubmit(onSubmit)">
								<card class="my-0 w-100 d-flex justify-content-center">
									<b-row class="custom__mx-3 pb-27 col-gap-30">
										<div class="col-md col-xs-12 px-0">
											<base-input
												alternative
												placeholder="お名前"
												name="お名前"
												:rules="{ required: true }"
												v-model="model.register_name"
												:disabled="disabled"
												label="お名前"
												:inputRequired="true"
												:labelClasses="'custom-form-input-label'"
												:inputClasses="'custom-input-class'"
											>
											</base-input>
										</div>
										<div class="col-md col-xs-12 px-0">
											<base-input
												alternative
												:disabled="disabled"
												placeholder="メールアドレス"
												name="メールアドレス"
												:rules="{
													required: true,
													email: true,
													checkExist: [model.email_address, 'email_address', disabled],
												}"
												v-model="model.email_address"
												label="メールアドレス"
												:inputRequired="true"
												:labelClasses="'custom-form-input-label'"
												:inputClasses="'custom-input-class'"
											>
											</base-input>
										</div>
									</b-row>
									<b-row class="custom__mx-3 pb-27 col-gap-30">
										<div class="col-md col-xs-12 px-0">
											<base-input
												name="種別"
												label="種別"
												:labelClasses="'custom-form-input-label'"
											>
												<b-form-radio
													class="custom-control-inline"
													v-model="selected"
													name="some-radios"
													value="2"
													:disabled="disabled"
													>法人</b-form-radio
												>
												<b-form-radio
													class="custom-control-inline"
													v-model="selected"
													name="some-radios"
													value="1"
													:disabled="disabled"
													>個人</b-form-radio
												>
											</base-input>
										</div>
										<div class="col-md col-xs-12 px-0">
											<base-input
												alternative
												placeholder="会社/屋号"
												name="会社/屋号"
												v-model="model.company_name"
												:disabled="disabled"
												label="会社/屋号"
												:labelClasses="'custom-form-input-label'"
												:inputClasses="'custom-input-class'"
											>
											</base-input>
										</div>
										<div class="col-md col-xs-12 px-0">
											<base-input
												alternative
												placeholder="電話番号"
												name="電話番号"
												v-model="model.phone_number"
												label="電話番号"
												@keydown="onKeyDown"
												@blur="checkPhoneNumber"
												:disabled="disabled"
												:labelClasses="'custom-form-input-label'"
												:inputClasses="'custom-input-class'"
												:numberCaption="true"
											>
											</base-input>
										</div>
									</b-row>
									<b-row class="custom__mx-3 pb-27 col-gap-30">
										<div class="col-md col-xs-12 px-0">
											<base-input
												alternative
												placeholder="ログインID"
												name="ログインID"
												:rules="{
													required: true,
													checkExist: [model.username, 'username'],
													validate: [
														[model.username],
														'ログインIDは英数字しか設定できません',
														checkUserName,
													],
												}"
												v-model="model.username"
												label="ログインID"
												:inputRequired="true"
												:labelClasses="'custom-form-input-label'"
												:inputClasses="'custom-input-class'"
											>
											</base-input>
										</div>
										<div class="col-md col-xs-12 px-0">
											<base-input
												alternative
												placeholder="パスワード"
												name="パスワード"
												type="password"
												:rules="{ required: true, validFormat: true }"
												v-model="model.login_password"
												label="パスワード"
												:inputRequired="true"
												:labelClasses="'custom-form-input-label'"
												:inputClasses="'custom-input-class'"
												:note="pwdRegex"
											>
											</base-input>
										</div>
										<div class="col-md col-xs-12 px-0">
											<base-input
												alternative
												placeholder="確認パスワード"
												name="確認パスワード"
												type="password"
												:rules="{
													required: true,
													confirmPassword: model.login_password,
												}"
												v-model="model.confirmpassword"
												label="確認パスワード"
												:inputRequired="true"
												:labelClasses="'custom-form-input-label'"
												:inputClasses="'custom-input-class'"
											>
											</base-input>
										</div>
									</b-row>
								</card>
								<div class="d-flex justify-content-center">
									<button type="submit" class="mt-4 customButton customButton__submit">
										登録
									</button>
								</div>
							</b-form>
						</validation-observer>
					</b-col>
				</b-row>
			</div>
		</b-container>
	</div>
</template>
<script>
import { Select, Option } from 'element-ui';
import UserService from '../../services/user.service.js';
import clientPaginationMixin from '../../mixin/clientPaginationMixin';
import CommonConst from '@/util/const';

export default {
	name: 'register',
	components: {
		[Select.name]: Select,
		[Option.name]: Option,
	},
	mixins: [clientPaginationMixin],
	data() {
		return {
			model: {
				register_name: '',
				email_address: '',
				username: '',
				company_name: '',
				phone_number: '',
				login_password: '',
				confirmpassword: '',
				client_id: '',
			},
			selected: '2',
			registerWaitingLink: 'registerwaiting',
			pwdRegex: CommonConst.PWD_REGEX,
		};
	},
	computed: {
		accountType() {
			return this.$route.query.account_type || 'user';
		},
		disabled() {
			return this.accountType !== 'user';
		},
	},
	mounted() {
		this.getInfoFromQuery();
	},
	methods: {
		checkUserName(username) {
			return /^[a-zA-Z0-9]+$/.test(username);
		},
		checkPhoneNumber() {
			if (this.model.phone_number) {
				this.model.phone_number = this.model.phone_number.replaceAll('-', '');
			}
		},
		onKeyDown(event) {
			const acceptedKeys = [
				'Backspace',
				'Delete',
				'ArrowLeft',
				'ArrowRight',
				'Minus',
				'NumpadSubtract',
			];
			if (
				(isNaN(Number(event.key)) && !acceptedKeys.includes(event.code)) ||
				event.code == 'Space'
			) {
				event.preventDefault();
			}
		},
		async onSubmit() {
			// this will be called only after form is valid. You can do an api call here to register users
			let registerInfo = {
				username: this.model.username,
				company_name: this.model.company_name,
				phone_number: this.model.phone_number,
				email_address: this.model.email_address,
				login_password: this.model.login_password,
				register_name: this.model.register_name,
				type: this.selected, // Company 1/Personal 2
				authority: this.accountType === 'client' ? 3 : 1,
			};
			let [registerResult, error] = await await (this.accountType == 'client'
				? UserService.update(this.model.client_id, registerInfo)
				: UserService.register(registerInfo));
			if (error) {
				console.log(error);
			} else {
				this.$router.push({
					path: this.registerWaitingLink,
					query: { email_address: this.model.email_address },
				});
			}
		},
		getInfoFromQuery() {
			const { register_name, email_address, company_name, phone_number, client_id } =
				this.$route.query;
			this.model.register_name = register_name;
			this.model.email_address = email_address;
			this.model.company_name = company_name;
			this.model.phone_number = phone_number;
			this.model.client_id = client_id;
		},
	},
};
</script>
